import React, { useContext, cloneElement } from "react"
import { CheckoutContext } from './providers/checkout'
import { UserContext } from './providers/user'
import styled from "styled-components"
import { textSpanContainsPosition } from "typescript"

const Wrapper = styled.div`
  ${props => props.theme.wrappersContainers.primaryWrapper};
  margin-bottom:3rem;
  display:grid;
  grid-gap: 1rem;
  grid-template-columns: auto;
  grid-template-rows: repeat(2, auto);

  @media ${(props) => props.theme.mq.sm} {
    grid-gap: 3rem;
  }

  @media ${(props) => props.theme.mq.md} {
    grid-template-columns: 3fr 2fr;
    grid-template-rows: auto;
  }

  .detailsButton{
    justify-content: flex-end;
  }

`

const CheckoutSteps = (props) => {
  // const userContext = useContext(UserContext)

  // return userContext.isLoggedIn()
  //   ? <Step {...props} />
  //   : <Login {...props} />
    return <Step {...props} />

}

const Step = ({ className, location, basepath, header, summary, children: steps }) => {
  const { checkout, setCheckout } = useContext(CheckoutContext)

  // functions to change the current step back or forward, limited by
  // the current progress (or not, depending on the 'advance' flag)
  const navigate = (step, advance) => {
    const highest = advance ? Math.min(step, steps.length) : checkout.highest
    const current = Math.max(Math.min(step, highest), 0)
    setCheckout({ current, highest })
  }
  const back = () => navigate(checkout.current - 1)
  const next = () => navigate(checkout.current + 1, true)

  // define current step and full path
  steps = steps.filter(record => (record));

  const step = steps[checkout.current]
  const path = `${basepath}/${step.props.path}`

  return (
    <>
      { header && cloneElement(header, { basepath, navigate, checkout, steps })}
      <Wrapper>
        <main className={className}>
          {cloneElement(step, { location, path, back, next })}
        </main>
        {summary}
      </Wrapper>
    </>
  )
}

// const Login = ({ className, basepath, login }) => (
//   <Wrapper>
//     <main className={className}>
//       {cloneElement(login, { path: `${basepath}/${login.props.path}` })}
//     </main>
//   </Wrapper>
// )

export default CheckoutSteps
