import React from "react"
import styled from "styled-components"
import Wrapper from "../wrapper"
import Status from "../status"
import Actions from "../actions"
import { Formik, Form, ErrorMessage } from "formik"
import { boolean, object } from "yup"
import Checkbox from "../../search/filters/checkbox"

const MoreInfo = styled.div`
  ${props => props.theme.fonts.smallerFontSize};
`

const Button = styled.button`
  ${props => props.theme.buttons[props.action]}
`

const CheckoutTerms = ({ path, title, back, next }) => {
  if (typeof window !== "undefined") {
    window.history.pushState({}, null, path)
  }

  const initialValues = {
    terms: false,
    subscribe: false,
  }

  const validationSchema = object({
    terms: boolean().oneOf([true], "Please accept the terms to continue"),
  })

  const onSubmit = () => next()

  return (
    <Wrapper>
      <Formik
        {...{ initialValues }}
        {...{ validationSchema }}
        {...{ onSubmit }}
      >
        {({ status, setFieldValue, handleSubmit }) => (
          <Form>
            <Checkbox
              whatPart="terms"
              title="I accept and understand the booking terms"
              displayCount=""
              onChildClick={e => {
                setFieldValue("terms", e.target.checked)
              }}
            />
            <ErrorMessage component="div" className="error" name="terms" />
            <MoreInfo>
              Read our <a title="Booking terms" target="_blank" href='/tcs'>booking terms</a> for more information.
            </MoreInfo>
            <Checkbox
              whatPart="subscribe"
              title="Subscribe to our newsletter"
              displayCount=""
              onChildClick={e => {
                setFieldValue("subscribe", e.target.checked)
              }}
            />
            <MoreInfo>
              Read our <a title="Privacy Policy" target="_blank" href="/privacy-policy">Privacy Policy</a> for more information.
            </MoreInfo>
            <Status formik={status} />
            <Actions>
              <Button type="submit" action="secondary" onClick={back}>
                Back
              </Button>
              <Button type="submit" action="primary">
                Next
              </Button>
            </Actions>
          </Form>
        )}
      </Formik>
    </Wrapper>
  )
}

export default CheckoutTerms
