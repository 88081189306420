import React from "react"
import styled from "styled-components"
import { Link } from "@reach/router"
import Icon from "../../utils/icon"
import Heading from "../../utils/heading"

const Header = styled.header`
  ${props => props.theme.wrappersContainers.primaryWrapper};
  margin-bottom:2rem;

  @media ${props => props.theme.mq.sm} {
    display:flex;
    align-items: baseline;
    margin-bottom:0;
  }

  .title{
    margin-top: 0;
    @media ${props => props.theme.mq.sm} {
      margin-right:2rem;
    }
  }

  ul {
    margin:0;
    padding:0;
    display: flex;
    justify-content:space-between;

    @media ${props => props.theme.mq.sm} {
      justify-content:unset;
    }
  }

  li, .linkWrapper {
    color: ${props => props.theme.colors.primary};
    font-size: 0.7rem;
    text-align:center;
    display: flex;
    flex-direction: column;
    align-items:center;

    @media ${props => props.theme.mq.sm} {
      flex-direction: row;
      align-items: normal;
      margin-right:1.5rem;
      ${props => props.theme.fonts.smallerFontSize};
    }

    &:not([current=true]){
      color: ${props => props.theme.colors.primaryFontColor};
      .stepNumber{
        background-color: rgba(${props => props.theme.colors.secondaryRGB} ,0.3);
      }
    }


  }

  a {
    display: flex;
    text-decoration:none;
  }

  .stepIcon {
    border: 1px solid;
    border-radius: 50%;
    width: 1.5rem;
    height: 1.5rem;
    border: 1px solid ${props => props.theme.colors.primary};
    position: relative;
    bottom: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media ${props => props.theme.mq.sm} {
      margin-right:0.5rem;
    }

    .svgWrapper{
      fill: ${props => props.theme.colors.primary};
    }
  }

  .stepNumber {
    padding-top: 4px;
    border-radius: 50%;
    width: 1.5rem;
    height: 1.5rem;
    text-align: center;
    background-color: ${props => props.theme.colors.primary};
    color: white;
    position: relative;
    bottom: 3px;
    @media ${props => props.theme.mq.sm} {
      padding-top: 3px;
      margin-right:0.5rem;
    }
  }
`

const CheckoutHeader = ({ basepath, title, navigate, checkout, steps }) => {
  const links = steps.map((step, index) => {
    const href = `${basepath}/${step.props.path}`

    const onClick = e => {
      e.preventDefault()
      navigate(index)
    }

    return (
      <li key={index} current={index === checkout.current ? 'true' : 'false'}>
        {index < checkout.current ? (
          <Link className="linkWrapper" onClick={onClick} to={href}>
            <div className="stepIcon">
              <Icon name="checkmark" height="0.75rem" />
            </div>
            <span className="stepText">{step.props.title}</span>
          </Link>
        ) : (
            <>
              <div className="stepNumber">{index + 1}</div>
              <span className="stepText">{step.props.title}</span>
            </>
          )}
      </li>
    )
  })

  return (
    <Header>
      <Heading type="title" as="h4" color={props => props.theme.colors.secondary}>
        {title}
      </Heading>
      <nav>
        <ul>{links}</ul>
      </nav>
    </Header>
  )
}

export default CheckoutHeader
