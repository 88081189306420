import React from "react"
//import styled from "styled-components"
import NumericInput from "../../utils/numericInput"
import FormControlLabel from "@material-ui/core/FormControlLabel"

const BookingExtra = ({ extra, onSelect, quantity }) => {

  function handleClick(value) {
    const extraQuantity = value;
    const extraUpdate = {
      id: extra.id,
      qty: extraQuantity,
    }

    onSelect(extraUpdate);
  }

  const nameString = extra.name;
  return (
      <FormControlLabel
        control={
          <NumericInput
          onChange={handleClick}
          value={quantity}
          range={{ min: 0, max: extra.maxQty, }}
        />
        }
        label={nameString}
        labelPlacement='start'
      />
  )
}

export default BookingExtra