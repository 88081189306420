import React, { useContext } from "react"
import styled from "styled-components"
import { BookingContext } from "./providers/booking"
import Heading from "../../utils/heading"

const Wrapper = styled.aside`

  .inner{
    background-color: ${props => props.theme.colors.lightGrey};
    padding: 1rem 1rem 0 1rem;
    ${props => props.theme.fonts.smallerFontSize};
  }

  .title{
    margin:0 0 1rem 0;
  }
`

const Booking = styled.div`
  padding: 1rem 0;

  ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0.2rem;
  }
`

const Total = styled.div`
  display: flex;
  justify-content: flex-end;
  background-color: ${props => props.theme.colors.mediumGrey};
  margin: ${props => (props.due ? `0.5rem` : 0)} -1rem 0;
  padding: 1rem;
    span{
      ${props => props.theme.fonts.standardFontSize};
      font-weight:bold;
      padding-left: 0.5rem;
    }
`

const CheckoutSummary = props => {
  const { booking } = useContext(BookingContext)
  const { title } = props

  const fromDate = booking.dates && booking.dates.from.toDateString()
  const toDate = booking.dates && booking.dates.to.toDateString()
  const guests = booking.guests || {}
  const extras = booking?.prices?.extras
  const total = booking?.prices?.totalPrice //data?.getBookingPrice.totalCost
  const deposit = booking?.prices?.deposit//data?.getBookingPrice.depositCost
  const propImageUrl = booking?.propImageUrl//data?.getProperty.images[0].url
  const propName = booking?.propName//data?.getProperty.name
  const propRef = booking.propRef;
  const paymentDue = booking?.prices?.paymentDue;
 // const balanceDue = booking?.prices?.due//data?.getBookingPrice.balanceDue
 // const isNotDue = Date.parse(balanceDue) > Date.now()
 // const due = booking.bookingType === 'paydeposit' && isNotDue ? deposit : total

  const guestString = Object.entries(guests).map(([key, val]) => {
    const type =
      parseInt(val) !== 1
        ? key
        : {
            adults: "adult",
            children: "child",
            infants: "infant",
            pets: "pet",
          }[key]

      return parseInt(val) ? `${val} ${type}` : null
  })
  .filter(Boolean)
  .join(" ")

  return (
    <Wrapper>
      <div className="inner">
        <Heading type="title" as="h4" color={props => props.theme.colors.secondary}>
          {title}
        </Heading>
        {booking.ref
          ? <>
            <img src={propImageUrl} alt="property" />
            <Booking>
              <Heading type="title" as="h6" color={props => props.theme.colors.secondary}>
                {propName}
              </Heading>
              <strong>Property ref: {propRef}</strong>
              <ul>
                <li key="arrive">Arrive: {fromDate}</li>
                <li key="depart">Depart: {toDate}</li>
                <li key="party">{guestString}</li>
                {extras?.map(extra => (
                    <li key={"extra-" + extra.id} >{extra.qty} x {extra.name} = £{extra.totalCost}</li>
                  ))
                }
              </ul>
            </Booking>
            <Total>
              <span>Total</span> <span>£{total}</span>
            </Total>
            {/* {booking.bookingType !== "paylater" && */
            <Total due>
              <span>To pay now</span> <span>£{paymentDue}</span>
            </Total>
            }
          </>
          : <p>No booking in progress</p>
        }
      </div>
    </Wrapper>
  )
}


export default CheckoutSummary
