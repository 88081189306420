import React, { useContext } from "react"
import styled from "styled-components"
import { rhythm } from "./typography"
import { BookingContext } from "./providers/booking"
import { gql, useQuery } from "@apollo/client"
import Member from "../search/filters/member"
import { useFormikContext } from "formik"

const Grid = styled.div`
  display: grid;
  grid: 1fr / 1fr;
  margin-bottom: ${rhythm(1)};

  @media ${props => props.theme.mq.sm} {
    grid: 1fr 1fr / 1fr 1fr;
  }

  div.inner > span {
    text-align: center;
  }
`

const GET_PROPERTY = gql`
  query GetProperty($id: Int) {
    getProperty(id: $id) {
      sleeps
      pets
      petsMaxQty
    }
  }
`

const Guests = () => {
  const { booking, setBooking } = useContext(BookingContext)
  const { setStatus } = useFormikContext();

  const { data } = useQuery(GET_PROPERTY, {
    variables: { id: booking.ref },
  })

  const pets = data?.getProperty?.pets;
  const petsMaxQty = pets && data?.getProperty?.petsMaxQty ? data.getProperty.petsMaxQty : 4;

  const updateGuests = async (n, type) => {
    const i = parseInt(n)

    if (
      type === "adults" &&
      i + booking.guests.children > data.getProperty.sleeps
    ) {
      return false
    }

    if (
      type === "children" &&
      i + booking.guests.adults > data.getProperty.sleeps
    ) {
      return false
    }

    try {
      await setBooking({
        ...booking,
        guests: {
          ...booking.guests,
          [type]: i,
        },
        // extras: extras
      })
    }
    catch (e) {
      console.error(e.message)
      setStatus({ error: 'Unable to update booking, please try again' })
    }
  }

  return data && booking.guests ? (
    <Grid>
      <Member
        title ="Adults"
        onClick={n => updateGuests(n, "adults")}
        concise={true}
        value={booking.guests.adults}
        range = {{ min:1, max: data.getProperty.sleeps,}}
      />
      <Member
        title ="Children"
        onClick={n => updateGuests(n, "children")}
        concise={true}
        value={booking.guests.children}
        range = {{ min:0, max: data.getProperty.sleeps - 1,}}
        ageRange="3-16"
      />
      <Member
        title ="Infants"
        onClick={n => updateGuests(n, "infants")}
        concise={true}
        value={booking.guests.infants}
        range = {{ min:0, max: data.getProperty.sleeps,}}
        ageRange="0-2"
      />
      <Member
        title ="Pets"
        onClick={n => updateGuests(n, "pets")}
        concise={true}
        value={booking.guests.pets}
        range = {{ min:0, max: pets?petsMaxQty:0,}}
      />

    </Grid>
  ) : null
}

export default Guests
