import React, { useContext, useEffect, useRef, useState } from "react"
import { navigate } from "gatsby"
import styled from "styled-components"
import Wrapper from "../wrapper"
import AutoSubmitForm from "./autosubmitform"
import AutoSubmitFormV2 from "./autosubmitformV2"
import Status from "../status"
import Actions from "../actions"
import { BookingContext } from "../providers/booking"
import { UserContext } from "../providers/user"
import { gql, useMutation } from "@apollo/client"
import { Formik } from 'formik'
import { Helmet } from "react-helmet"
import Frame from 'react-frame-component'

const Button = styled.button`
  ${props => props.theme.buttons[props.action]}
`

const framestyle = {
  border: '0 solid white',
  width: '100%',
  height: '680px'
};


const CREATE_PAYMENT_SESSION = gql`
  mutation {
    createPaymentSession {
      key
      expiry
    }
  }
`

const CARD_PAYMENT = gql`
  mutation CardPayment($booking: BookingInput!) {
    cardPayment(booking: $booking) {
      transactionId
      status
      statusCode
      statusDetail
      acsTransId
      dsTranId
      cReq
      paReq
      acsUrl
      amount
      MD
    }
  }
`

const CREATE_BOOKING = gql`
  mutation CreateBooking($booking: BookingInput!) {
    createBooking(booking: $booking) {
      ref
      bookingRef
      status
    }
  }
`

const CheckoutPayment = ({ location, path, title, back }) => {

  if (typeof window !== "undefined") {
    window.history.pushState({}, null, path)
  }

  const { booking, setBookingRef,setBookingStatus } = useContext(BookingContext)

  const sagePayRef = useRef()
  const sessionKeyRef = useRef()
  const formikRef = useRef()
  const formRef = useRef()
  let apolloStatus = {}

  if ( booking.status === "confirmed" ) {
    navigate('/confirmation', { state: { booking } })
  }
  console.log("********render******")

  const { user } = useContext(UserContext)

  const [createPaymentSession, {
    data: sessionData,
    called: sessionRequested,
    client: apolloClient
  }] = useMutation(CREATE_PAYMENT_SESSION)

  const [payStatus, setPayStatus] = useState({ bookingId: null, result: {status: 'notsent'}})

  const onSubmit = values => {
    if (values.next) {
      sagePayRef.current.tokenise()
      formikRef.current.setSubmitting(false)
    } else {
      back()
    }
  }

  /* ********************************* */
  // Create an Opayo card payment session
  const createSession = () =>{

    console.log("Create session")

    createPaymentSession().then( sessionResult=>{
      console.log("Session Created",sessionResult)
      const merchantSessionKey = sessionResult.data.createPaymentSession.key
      sessionKeyRef.current = merchantSessionKey 
    })
    .catch(error =>{
      apolloStatus = {
        error: error.message
      }
      console.error(error.message)    
    })
  }

  /* ***************************************************************** */
  // Make the provisional booking which should be held for 10 mins or so.
  const makeBooking = () => {
    apolloClient.mutate({
      mutation: CREATE_BOOKING,
      variables: {
        booking: buildBooking(null)
      },
    })
      .then(result => {
        console.log(`booking id: ${result.data.createBooking.ref}, status: ${result.data.createBooking.status}`)
        if ( result.data.createBooking.status === "provisional" ) {
          console.log("set booking")
          setBookingRef(
            result.data.createBooking.ref,
            result.data.createBooking.bookingRef,
            result.data.createBooking.status
          )
        } else {
          console.log("Booking failed", result.data.createBooking.message)
        }
      })
      .catch(error => {
        let message
        if (error.message.includes('No availability')) {
          message = 'Unfortunately your dates are no longer available, please rebook with alternative dates'
        } else if (error.message.includes('Invalid user')) {
          message = 'Your session has expired, please login again'
        } else if (error.message.includes('Invalid price')) {
          message = 'Unfortunately the price has changed since the booking began, please make a new booking'
        } else{
          message = error.message
        }
        console.error(error.message)
        apolloStatus = {
          error: message
        }
      })
  }

  const buildBooking = () => ({
    bookingId: booking?.id ? booking.id.toString() : null,
    bookingRef: booking?.bookingRef ? booking.bookingRef.toString() : null,
    property: {
      ref: booking.ref,
    },
    customer: {
      title: user.title,
      firstName: user.firstname,
      lastName: user.lastname,
      addresses: {
        street: user.street,
        locality: user.locality,
        town: user.town,
        county: user.county,
        postcode: user.postcode,
        country: "GB",
      },
      contacts: [{
        type: 'EMAIL',
        value: user.email,
      },
      {
        type: 'PHONE',
        value: user.telephone,
      },
      {
        type: 'MOBILE',
        value: user.telephone,
      }]
    },
    adults: booking.guests.adults,
    children: booking.guests.children,
    infants: booking.guests.infants,
    pets: booking.guests.pets,
    start: booking.dates.from,
    end: booking.dates.to,
    extras: booking.extras?.map(extra => ({id: extra.id, qty: extra.qty})),
    notes: booking.notes,
    source: booking.source,
    total: booking.prices.totalPrice,
    deposit: booking.prices.deposit,
    due: booking.prices.balanceDueDate,
    bookingType: booking.bookingType,
    browser: booking.browser,
  })

  /* *********************************************** */
  const onTokenise = result => {
    // 3d secure message response back from iFrame
    const handleAuthorisation = (event) => {
      if (event.data.type === 'authorisation-result') {

        console.log("3DS msg received: ",event.data) 

        window.removeEventListener('message', handleAuthorisation);
        console.log("remove 3DS event listener")

        console.log("Booking ID: ", booking.id)

        const transactionId = event.data.payload.transactionId
        const amount = event.data.payload.amount.totalAmount/100
        const status = "confirmed"
        
        setBookingStatus(status, transactionId, amount)

        return false
      }
    };

    if (result.success) {
      if(formikRef.current){
        formikRef.current.setSubmitting(true)
      }
      else{
        console.log("warning formikref current null")
      }
      console.log("Initiate Card Payment")

      apolloClient.mutate({
        mutation: CARD_PAYMENT,
        variables: {
          booking: {
            ...buildBooking(),
            paymentToken: result.cardIdentifier,
            amount:booking.prices.paymentDue,
            sessionKey: sessionKeyRef.current,
          }
        },
      })
      .then(result => {
        const payment = result.data.cardPayment
        if ( payment.statusCode === "0000" ) {
          //Frictionless process
          const transactionId = payment.transactionId
          const amount = payment.amount
          const status = "confirmed"
          setBookingStatus(status, transactionId, amount)

          console.log("Frictionless Payment complete")

        } else if (payment.statusCode === "2007" || payment.statusCode === "2021") {
          //3DS Auth fallback
          console.log(" 3DS required: ")
          console.log(payment)

          console.log("Add 3DS event listener")
          window.addEventListener('message', handleAuthorisation);

          setPayStatus({...payStatus, bookingId: booking.id, result: payment})
        }
      })
      .catch(error => {
        let message
        if (error.message.includes('Authorisation was Declined')) {
          message = 'The card was declined by your bank, please try an alternative payment method'
        }
        if (error.message.includes('Invalid user')) {
          message = 'Your session has expired, please login again'
        }
        if (error.message.includes('rejected')) {
          message = 'Unfortunately your card has been rejected, please try a new card'
        }
        console.error(error.message)
        if(formikRef.current){
          formikRef.current.setSubmitting(false)
          formikRef.current.setStatus({
            error: message
          })
        }
      })
    } else if (result.error.httpCode === 401) {
      apolloClient.mutate({
        mutation: CREATE_PAYMENT_SESSION
      })
        .then(result => {
          sessionKeyRef.current = result.data.createPaymentSession.key
          sagePayRef.current.tokenise({
            newMerchantSessionKey: sessionKeyRef.current
          })
        })
        .catch(error => {
          formikRef.current.setStatus({
            error: 'Session timeout, please refresh the page and try again'
          })
          formikRef.current.setSubmitting(false)
          console.error(error.message)
        })
    } else {
      formikRef.current.setStatus({
        error: 'Unable to encrypt card details, please try refreshing the page'
      })
      console.error(result.error.errorMessage)
    }
  }

  //************************************** */
  // post render hook

  useEffect(() => {
    var interval

    console.log("effect")
    if (booking?.id ) {
      console.log("booking id valid")
      if (sessionKeyRef.current){
        console.log("Session valid")
        if(!sagePayRef.current){
          interval = window.setInterval(() => {
            if (window.sagepayCheckout) {
              window.clearInterval(interval)

              try {
                sagePayRef.current = window.sagepayCheckout({
                  merchantSessionKey: sessionKeyRef.current,
                  onTokenise
                })
                console.log("Sagepay initialised")
              }
              catch (e) {
                if (!e.message.includes('Parent page already initialised')) {
                  formikRef.current.setStatus({ error: 'Unable to load payment form, please try refreshing the page' })
                  console.error(e.message)
                }
              }

               formRef.current.style.visibility = 'visible'
            }
          }, 100)
        }
      } else {
        console.log("no session yet")
      }
    } else{
      console.log("no booking id yet")
    }

    return () => {
      if (interval) {
        window.clearInterval(interval)
      }
      if (sagePayRef.current) {
        console.log("destroying sagepay to remove event handlers")
        sagePayRef.current.destroy()
        sagePayRef.current = null;
      }
    }
  },[booking.id,sessionData])

  /* ************************************************************ */

  if(booking.id && !sessionRequested && !sessionData){
    createSession()
  }

  if (!booking?.id) {
    console.log ("No booking ID found. Making booking...")
    makeBooking()
    console.log("intiated mb")
  } 

  // Finally the template
  return (
    <Wrapper>
      <Helmet>
        { process.env.GATSBY_PAYMENT_ENV === 'live' 
        ? <script crossorigin src="https://pi-live.sagepay.com/api/v1/js/sagepay.js"></script>
        : <script crossorigin src="https://pi-test.sagepay.com/api/v1/js/sagepay.js"></script>
        }
       
      </Helmet>
      { (payStatus.bookingId !== booking.id ||payStatus.result.status === 'notsent') &&
      <Formik initialValues={{}} onSubmit={onSubmit} innerRef={formikRef}>
        {({ status, setFieldValue, handleSubmit, isSubmitting }) => (
          <form ref={formRef} style={{ visibility: 'hidden' }}>
            <div id="sp-container" />
            <Status formik={status} apollo={apolloStatus} />
            <Actions>
              <Button
                type="submit"
                action="secondary"
                onClick={e => {
                  setFieldValue("next", false)
                  handleSubmit(e)
                }}
              >
                Back
              </Button>
              <Button
                type="submit"
                action="primary"
                disabled={isSubmitting}
                onClick={e => {
                  setFieldValue("next", true)
                  handleSubmit(e)
                }}
              >
                Pay now
              </Button>
            </Actions>
          </form>
        )}
      </Formik>
      } 
      { payStatus.bookingId === booking.id && payStatus.result.statusCode === '2007' &&
      <Frame style={framestyle} >
          <AutoSubmitForm payload={payStatus.result} />
      </Frame>
      }
      { payStatus.bookingId === booking.id && payStatus.result.statusCode === '2021' &&
      <Frame style={framestyle} >
          <AutoSubmitFormV2 payload={payStatus.result} />
      </Frame>
      }
    </Wrapper>
  )
}

export default CheckoutPayment
