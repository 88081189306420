import React, { useContext } from "react"
import styled from "styled-components"
import Deposit from "../deposit"
import Wrapper from "../wrapper"
import Status from "../status"
import Actions from "../actions"
import { BookingContext } from "../providers/booking"
import { Formik, Field, Form, ErrorMessage } from "formik"
import { string, object } from "yup"
import Heading from "../../../utils/heading"
import BookingExtras from "../extras"
import Box from '@material-ui/core/Box';

const Input = styled.input`
  ${props => props.theme.formElements.input};
`

const Select = styled.select`
  ${props => props.theme.formElements.select};
`

const Button = styled.button`
  ${props => props.theme.buttons[props.action]}
`

const CheckoutOptions = ({ path, title, back, next }) => {
  if (typeof window !== "undefined") {
    window.history.pushState({}, null, path)
  }

  const { booking, setBooking } = useContext(BookingContext)

  const initialValues = {
    notes: booking.notes || "",
    source: booking.source || "Internet Search",
  }

  const validationSchema = object({
    source: string().required("Required"),
  })

  const onSubmit = async (values, { setStatus }) => {
    try {
      await setBooking({
        ...booking,
        notes: values.notes,
        source: values.source,
      })
      values.next ? next() : back()
    }
    catch (e) {
      console.error(e.message)
      setStatus({ error: 'Unable to update booking, please try again' })
    }
  }

  const addExtra = async (value) => {
    // const extras = updateBookingExtras(value)
    // try {
    //   await setBooking({
    //     ...booking,
    //     extras: extras,
    //   })
    // }
    // catch (e) {
    //   console.error(e.message)
    //   //setStatus({ error: 'Unable to update extras, please try again' })
    // }
  }

  return (
    <Wrapper>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ status, setFieldValue, handleSubmit }) => (
          <Form>
            <Heading as="h4" color={props => props.theme.colors.secondary}>
            Any extras you would like to add? (optional)
            </Heading>
            <Box my={2} mx={1}>
              <BookingExtras
                onUpdate={addExtra}
              />
            </Box>
            <Heading as="h4" color={props => props.theme.colors.secondary}>
              How would you like to pay?
            </Heading>
            <Deposit />
            <Heading as="h4" color={props => props.theme.colors.secondary}>
              Let us know about any special requirements (optional)
            </Heading>
            <Field name="notes" as={Input} type="text" />
            <ErrorMessage component="div" className="error" name="notes" />
            <Heading as="h4" color={props => props.theme.colors.secondary}>
              How did you hear about us?
            </Heading>
            <Field
              name="source"
              as={Select}
              type="text"
              placeholder="How did you hear?"
            >
              <option>Budleigh Salterton Town Guide</option>
              <option>Country Living</option>
              <option>Exmouth Town Guide</option>
              <option>Internet Search</option>
              <option>Previous Customer</option>
              <option>Recommended</option>
              <option>Sea Shanty Chalets</option>
              <option>Sidmouth International School</option>
              <option>Sidmouth Tourist Information Centre</option>
              <option>Sidmouth Town Guide</option>
              <option>Social Media</option>
              <option>Visit Devon</option>
            </Field>
            <ErrorMessage component="div" className="error" name="source" />
            <Status formik={status} />
            <Actions>
              <Button
                type="submit"
                action="secondary"
                onClick={e => {
                  setFieldValue("next", false)
                  handleSubmit(e)
                }}
              >
                Back
              </Button>
              <Button
                type="submit"
                action="primary"
                onClick={e => {
                  setFieldValue("next", true)
                  handleSubmit(e)
                }}
              >
                Next
              </Button>
            </Actions>
          </Form>
        )}
      </Formik>
    </Wrapper>
  )
}

export default CheckoutOptions
