import React, { useState } from "react"
import styled from "styled-components"

import Icon from "../../../utils/icon"

const HiddenCheckbox = styled.input.attrs({
  type: "radio",
})`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

const StyledCheckbox = styled.div`
  ${props => props.theme.formElements.checkbox};
`

const Wrapper = styled.div`
  display: block;
  vertical-align: middle;
  margin-top: 5px;
  label > span {
    margin-left: 15px;
  }
`

const Radio = ({ name, values = {}, checked = 0, onChildClick, ...props }) => {

  const [checkedState, setCheckedState] = useState(checked)

  function handleClick(i, event) {
    setCheckedState(i)
    onChildClick(event)
  }

  return Object.entries(values).map(([key, value], i) => (
    <Wrapper key={i}>
      <label>
        <HiddenCheckbox
          onChange={(e) => handleClick(i, e)}
          checked={i === checkedState}
          value={value}
          name={name}
        />
        <StyledCheckbox checked={i === checkedState} >
          {i === checkedState &&
            <Icon name="checkmark" width="16px" />
          }
        </StyledCheckbox>
        <span>{key}</span>
      </label>
    </Wrapper>
  ))
}

export default Radio
