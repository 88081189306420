import React, { useEffect, useRef } from "react"
import { Buffer } from "buffer"

const AutoSubmitFormV2 = ({ payload }) => {
  const formRef = useRef(null);
  useEffect(() => {
    formRef.current.submit();
  }, [])

  return (
    <form ref={formRef} method="POST" action={payload.acsUrl}>
        <input type="hidden" name="creq" value={payload.cReq} />
        <input type="hidden" name="threeDSSessionData" value={Buffer.from(payload.MD).toString('base64')} />
        <p>Proceeding to 3D secure, please wait...</p> 
        {/* <input type="submit" value="Go"/> */}
    </form>
  )
}

export default AutoSubmitFormV2
