import React, { useContext } from "react"
import styled from "styled-components"
import { rhythm } from "./typography"
import { BookingContext } from "./providers/booking"
import Radio from "../search/filters/radio"
import { useFormikContext } from "formik"

const Wrapper = styled.div`
  margin-bottom: ${rhythm(1)};
`

const Deposit = () => {
  const { booking, setBooking } = useContext(BookingContext)
  const { setStatus } = useFormikContext();

  const updateDeposit = async (event) => {
    const bookingType = event.target.checked ? event.target.value : 'paylater'
      // (event.target.value === "paydeposit" && event.target.checked) ||
      // (event.target.value === "payfull" && !event.target.checked)

    try {
      await setBooking({
        ...booking,
        bookingType,
      })
    }
    catch (e) {
      console.error(e.message)
      setStatus({ error: 'Unable to update booking, please try again' })
    }
  }

  return (
    <Wrapper>
      <Radio
        name="deferred"
        values={{
          "Pay later (hold for 48 hours)": "paylater",
          "Pay in full by card": "payfull",
          "Pay the deposit by card": "paydeposit"
        }}
        checked={booking.bookingType === 'paydeposit' ? 1 : 0}
        onChildClick={updateDeposit}
        reset={!booking.bookingType === 'paydeposit'}
      />
    </Wrapper>
  )
}

export default Deposit
