import React, { useContext } from "react"
import { navigate } from "gatsby"
import styled from "styled-components"
import Wrapper from "../wrapper"
import Status from "../status"
import Actions from "../actions"
import { Formik, Form, ErrorMessage } from "formik"
import { boolean, object } from "yup"
import { gql, useMutation } from "@apollo/client"

import Checkbox from "../../search/filters/checkbox"
import { UserContext } from "../providers/user"
import { BookingContext } from "../providers/booking"


const MoreInfo = styled.div`
  ${props => props.theme.fonts.smallerFontSize};
`

const Button = styled.button`
  ${props => props.theme.buttons[props.action]}
`

const CREATE_BOOKING = gql`
  mutation CreateBooking($booking: BookingInput!) {
    createBooking(booking: $booking) {
      ref
      status
    }
  }
`

const DeferedPaymentTerms = ({ path, title, back, next }) => {
  if (typeof window !== "undefined") {
    window.history.pushState({}, null, path)
  }

  const { booking, setBooking } = useContext(BookingContext)
  const { user } = useContext(UserContext)

  if ( booking.status === "held" || booking.status === "confirmed") {
    navigate('/confirmation', { state: { booking } })
  }
  const [createBooking, {
    // error: apolloError,
    // data: apolloData,
    // called: apolloCalled,
    client: apolloClient
  }] = useMutation(CREATE_BOOKING)

  const initialValues = {
    terms: false,
    subscribe: false,
  }

  const validationSchema = object({
    terms: boolean().oneOf([true], "Please accept the terms to continue"),
  })

  const buildBooking = paymentToken => ({
    bookingId: booking?.id ? booking.id.toString() : null,
    property: {
      ref: booking.ref,
    },
    customer: {
      title: user.title,
      firstName: user.firstname,
      lastName: user.lastname,
      addresses: {
        street: user.street,
        locality: user.locality,
        town: user.town,
        county: user.county,
        postcode: user.postcode,
        country: "GB",
      },
      contacts: [{
        type: 'EMAIL',
        value: user.email,
      },
      {
        type: 'PHONE',
        value: user.telephone,
      },
      {
        type: 'MOBILE',
        value: user.telephone,
      }]
    },
    adults: booking.guests.adults,
    children: booking.guests.children,
    infants: booking.guests.infants,
    pets: booking.guests.pets,
    start: booking.dates.from,
    end: booking.dates.to,
    extras: booking.extras?.map(extra => ({id: extra.id, qty: extra.qty})),
    notes: booking.notes,
    source: booking.source,
    total: booking.prices.totalPrice,
    deposit: booking.prices.deposit,
    due: booking.prices.balanceDueDate,
    bookingType: booking.bookingType,
    paymentToken: paymentToken,
  //  sessionKey: sessionKeyRef.current,
  })

  // Make the booking
  const makeBooking = () => {
    apolloClient.mutate({
      mutation: CREATE_BOOKING,
      variables: {
        booking: buildBooking(null)
      },
    })
      .then(result => {
        console.log(`booking id: ${result.data.createBooking.ref}, status: ${result.data.createBooking.status}`)
        if ( result.data.createBooking.status === "held" ) {
          setBooking({
            ...booking,
            id: result.data.createBooking.ref,
            status: result.data.createBooking.status
          })
        } else {
          console.log("Booking failed", result.data.createBooking.message)
        }
      })
      .catch(error => {
        let message
        if (error.message.includes('No availability')) {
          message = 'Unfortunately your dates are no longer available, please rebook with alternative dates'
        }
        if (error.message.includes('Invalid user')) {
          message = 'Your session has expired, please login again'
        }
        if (error.message.includes('Invalid price')) {
          message = 'Unfortunately the price has changed since the booking began, please make a new booking'
        }
        console.error(error.message)    
      })
  }


  const onSubmit = () => {
    makeBooking();
  }

  return (
    <Wrapper>
      <Formik
        {...{ initialValues }}
        {...{ validationSchema }}
        {...{ onSubmit }}
      >
        {({ status, setFieldValue, handleSubmit }) => (
          <Form>
            <Checkbox
              whatPart="terms"
              title="I accept and understand the booking terms"
              displayCount=""
              onChildClick={e => {
                setFieldValue("terms", e.target.checked)
              }}
            />
            <ErrorMessage component="div" className="error" name="terms" />
            <MoreInfo>
              Read our <a title="Booking terms" target="_blank" href='/tcs'>booking terms</a> for more information.
            </MoreInfo>
            <Checkbox
              whatPart="subscribe"
              title="Subscribe to our newsletter"
              displayCount=""
              onChildClick={e => {
                setFieldValue("subscribe", e.target.checked)
              }}
            />
            <MoreInfo>
              Read our <a title="Privacy Policy" target="_blank" href="/privacy-policy">Privacy Policy</a> for more information.
            </MoreInfo>
            <Status formik={status} />
            <Actions>
              <Button type="submit" action="secondary" onClick={back}>
                Back
              </Button>
              <Button type="submit" action="primary">
                Confirm Booking
              </Button>
            </Actions>
          </Form>
        )}
      </Formik>
    </Wrapper>
  )
}

export default DeferedPaymentTerms
