import styled from 'styled-components';

const Actions = styled.div`
  display: flex;
  justify-content: space-between;

  button {
    margin: 1rem 0;
    min-width: 10rem;
  }
`

export default Actions
