import React from "react"
import styled from 'styled-components';
import { rhythm } from "./typography"

const Component = ({ formik = {}, apollo = {}, className }) => (
  <div className={className}>{apollo.error || formik.error}</div>
)

const Status = styled(Component)`
  font-size: 1.1em;
  margin: ${rhythm(0.5)} 0;
  color: ${props => props.theme.colors.error};
`

export default Status
