import React, { useContext } from "react"
//mport styled from "styled-components"
import BookingExtra from "./extra"
//import { gql, useQuery } from "@apollo/client"
import { BookingContext } from "./providers/booking"
import FormGroup from '@material-ui/core/FormGroup';


const BookingExtras = ({ children, onUpdate }) => {

  const { booking, addBookingExtra } = useContext(BookingContext)
  // const { data } = useQuery(GET_BOOKING_EXTRAS, {
  //   variables: { id: booking.ref },
  // })

  function handleClick(value) {
    addBookingExtra({id:value.id, qty:value.qty})
    onUpdate(value);
  }

  function getQuantity(xid) {
    //console.log(booking.extras)
    const idmatch = booking.extras !== undefined && booking.extras !== null && booking.extras.length ? booking.extras.find(extra => extra.id === xid) : undefined
    return idmatch !== undefined ? idmatch.qty : 0
  }
  return (
    <>
    {children}
    <FormGroup>
      
      {booking?.options
        ? <>
          { booking.options?.map( extra => {
            // if ( !extra.compulsory && extra.name !== 'Pet') {
              return (
                <BookingExtra 
                  key={extra.id}
                  extra={extra}
                  onSelect={handleClick}
                  quantity={getQuantity(extra.id)}
                />
              )
            // }
            return null;
          })}
        </>
        : <p>No extras available for this property</p>
      }

    </FormGroup>
    </>
  )
}

export default BookingExtras