import React, { useContext, useEffect, useRef } from "react"
import { Helmet } from "react-helmet"
import styled from "styled-components"
import Guests from "../guests"
import Wrapper from "../wrapper"
import Status from "../status"
import Actions from "../actions"
import { UserContext } from "../providers/user"
import { Formik, Field, Form, ErrorMessage } from "formik"
import { string, object } from "yup"
import Heading from "../../../utils/heading"

const Name = styled.div`
  display:grid;
  grid-gap: 0.5rem 1rem;
  grid-template-columns: auto;
  grid-template-rows: repeat(3, auto);
  grid-template-areas:
        "title"
        "fName"
        "lname";

        @media ${(props) => props.theme.mq.xs} {
          grid-template-columns: repeat(2, auto);
          grid-template-rows: repeat(2, auto);
          grid-template-areas:
              "title ."
              "fName lname";
        }

        @media ${(props) => props.theme.mq.sm} {
          grid-template-columns: 1fr 2fr 2fr;
          grid-template-rows: auto;
          grid-template-areas:
              "title fName lname";
        }

  .title{
    grid-area: title;
    width: 100%;
    max-width: 150px;
  }

  .fName{
    grid-area: fName;
  }

  .lName{
    grid-area: lname;
  }
  
`

const Input = styled.input`
  ${props => props.theme.formElements.input};
`

const Button = styled.button`
  ${props => props.theme.buttons[props.action]}
`

const CheckoutDetails = ({ path, title, next }) => {
  if (typeof window !== "undefined") {
    window.history.pushState({}, null, path)
  }

  const pcaRef = useRef()
  const formikRef = useRef()

  const { user, setUser } = useContext(UserContext)

  const initialValues = {
    title: user.title || "",
    firstname: user.firstname || "",
    lastname: user.lastname || "",
    email: user.email || "",
    street: user.street || "",
    locality: user.locality || "",
    town: user.town || "",
    county: user.county || "",
    postcode: user.postcode || "",
    telephone: user.telephone || "",
  }

  const validationSchema = object({
    title: string(),
    firstname: string().required("Required"),
    lastname: string().required("Required"),
    email: string()
    .email('Please use a valid email address')
    .required('Please enter your email address'),
    street: string().required("Required"),
    town: string().required("Required"),
    county: string().required("Required"),
    postcode: string().required("Required"),
    telephone: string().required('Please enter a mobile number we can contact you on during your stay'),
  })

  const onSubmit = async (values, { setStatus }) => {
    try {
      await setUser({
        ...user,
        title: values.title,
        firstname: values.firstname,
        lastname: values.lastname,
        email:values.email,
        street: values.street,
        locality: values.locality,
        town: values.town,
        county: values.county,
        postcode: values.postcode,
        telephone: values.telephone,
      })
      next()
    }
    catch (e) {
      console.error(e.message)
      setStatus({ error: 'Unable to update user, please try again' })
    }
  }

  useEffect(() => {
    let interval

    if (!pcaRef.current) {
      interval = window.setInterval(() => {
        if (window.pca) {
          window.clearInterval(interval)

          var fields = [
            { element: "street", field: "Line1", mode: window.pca.fieldMode.SEARCH },
            { element: "locality", field: "Line2", mode: window.pca.fieldMode.PRESERVE },
            { element: "town", field: "City", mode: window.pca.fieldMode.PRESERVE },
            { element: "county", field: "Province", mode: window.pca.fieldMode.PRESERVE },
            { element: "postcode", field: "PostalCode", mode: window.pca.fieldMode.SEARCH },
          ]

          pcaRef.current = new window.pca.Address(fields, { key: "EF46-MT65-XA49-UC88" })

          pcaRef.current.listen("populate", function (address, variations) {
            formikRef.current.setValues({
              ...formikRef.current.values,
              street: address.Line1,
              locality: address.Line2,
              town: address.City,
              county: address.Province,
              postcode: address.PostalCode,
            })
          })
        }
      }, 100)
    }

    return () => {
      if (interval) {
        window.clearInterval(interval)
      }
    }
  })

  return (
    <Wrapper>
      <Helmet>
        <link rel="stylesheet" type="text/css" href="https://services.postcodeanywhere.co.uk/css/address-3.91.css" />
        <script type="text/javascript" src="https://services.postcodeanywhere.co.uk/js/address-3.91.js" />
      </Helmet>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        innerRef={formikRef}
      >
        {({ status }) => (
          <Form>
            <Name>
              <div className="title">
                <Field
                  name="title"
                  as={Input}
                  type="input"
                  placeholder="Title"
                />
                <ErrorMessage component="div" className="error" name="title" />
              </div>
              <div className="fName">
                <Field
                  name="firstname"
                  as={Input}
                  type="text"
                  placeholder="First Name"
                />
                <ErrorMessage
                  component="div"
                  className="error"
                  name="firstname"
                />
              </div>
              <div className="lName">
                <Field
                  name="lastname"
                  as={Input}
                  type="text"
                  placeholder="Last Name"
                />
                <ErrorMessage
                  component="div"
                  className="error"
                  name="lastname"
                />
              </div>
            </Name>
            <Field name="email" as={Input} type="email" placeholder="Email" />
            <ErrorMessage component="div" className="error" name="email" />
            <Field
              name="street"
              as={Input}
              type="text"
              placeholder="Address line 1"
            />
            <ErrorMessage component="div" className="error" name="street" />
            <Field
              name="locality"
              as={Input}
              type="text"
              placeholder="Address line 2"
            />
            <ErrorMessage component="div" className="error" name="locality" />
            <Field
              name="town"
              as={Input}
              type="text"
              placeholder="Town or city"
            />
            <ErrorMessage component="div" className="error" name="town" />
            <Field
              name="county"
              as={Input}
              type="text"
              placeholder="County"
            />
            <ErrorMessage component="div" className="error" name="county" />
            <Field
              name="postcode"
              as={Input}
              type="text"
              placeholder="Postcode"
            />
            <ErrorMessage component="div" className="error" name="postcode" />
            <Field
              name="telephone"
              as={Input}
              type="text"
              placeholder="Mobile Number"
            />
            <ErrorMessage component="div" className="error" name="telephone" />

            <Heading type="mainTitle" as="h4" color={props => props.theme.colors.primary}>
              Guests
            </Heading>
            <Guests />

            <Status formik={status} />

            <Actions className="detailsButton" >
              <Button type="submit" action="primary">
                Next
              </Button>
            </Actions>
          </Form>
        )}
      </Formik>
    </Wrapper>
  )
}

export default CheckoutDetails
