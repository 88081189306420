import React, { useEffect, useRef } from "react"

const AutoSubmitForm = ({ payload }) => {
  const formRef = useRef(null);
  useEffect(() => {
    formRef.current.submit();
  }, [])

  return (
    <form ref={formRef} method="POST" action={payload.acsUrl}>
        <input type="hidden" name="PaReq" value={payload.paReq} />
        <input type="hidden" name="TermUrl" value={process.env.GATSBY_GRAPH_ADDR + "/notify/"} />
        <input type="hidden" name="MD" value={payload.MD} />
    </form>
  )
}

export default AutoSubmitForm
