import React,{ useContext } from "react"
import styled from "styled-components"
import CheckoutHeader from "../components/checkout/header"
import CheckoutSteps from "../components/checkout/steps"
//import CheckoutLogin from "../components/checkout/login"
import CheckoutDetails from "../components/checkout/steps/details"
import CheckoutOptions from "../components/checkout/steps/options"
import CheckoutTerms from "../components/checkout/steps/terms"
import DeferedPaymentTerms from "../components/checkout/steps/deferedTerms"
import CheckoutPayment from "../components/checkout/steps/payment"
import CheckoutSummary from "../components/checkout/summary"
import CheckoutConfirm from "../components/checkout/steps/confirm"
import { BookingContext } from "../components/checkout/providers/booking"


const StyledSteps = styled(CheckoutSteps)`

`

const Checkout = ({ location }) => {

  const { booking } = useContext(BookingContext)

  return(
    <StyledSteps
      location={location}
      basepath="/checkout"
      header={<CheckoutHeader title="Secure booking" />}
      summary={<CheckoutSummary title="Summary" />}
    >
    {/*  <CheckoutLogin path="login" title="Login or register" /> */}
      <CheckoutDetails path="details" title="Details" />
      <CheckoutOptions path="options" title="Options" />
      {
        booking.bookingType ==="paylater" ?
          <DeferedPaymentTerms path="deferedpaymentterms" title="T&C's" />
        :
          <CheckoutTerms path="terms" title="T&C's" />
      }
      {
         booking.bookingType !=="paylater" &&  
         <CheckoutPayment path="payment" title="Payment" />       
      }
      <CheckoutConfirm path="confirm" title="Confirmation" />
    </StyledSteps>
  )
}

export default Checkout
